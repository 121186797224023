import React from 'react';
import { TestimonialCarouselNoHeadshot } from './TestimonialCarouselNoHeadshot';

const TestimonialSection = ({ component }) => {
  const {
    type
  } = component
  switch (type) {
    case "carousel-no-headshots":
      return <TestimonialCarouselNoHeadshot component={component} />;
    default: 
      return <TestimonialCarouselNoHeadshot component={component} />;
  }
}

export default TestimonialSection
