import styled, { css } from "styled-components"

const TestimonialCarouselWrapper = styled.div`
  font-family: "Roboto";
  padding: 96px 0;
  background: #F2F4F7;
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
  `}
  .testimonial-carousel--container {
    position: relative;
  }
  .heading {
    padding: 0 0 96px 0;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.1px;
    color: #101828;
  }
  .testimonial-carousel--main-container {
    margin: 0 auto;
    @media (max-width: 992px) {
      max-width: unset;
    }
    .testimonial-carousel--item {
      .testimonial-carousel--item-wrapper {
        max-width: 770px;
        @media (max-width: 992px) {
          width: calc(100vw - 150px);
        }
        margin: 0 15px;
        display: flex;
        align-items: center;
      }
      .testimonial-carousel--item-text-container {
        flex: 1;
        min-height: 335px;
        border-radius: 8px;
        position: relative;
        background-image: url('/images/testimonial-back.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;
        background-position-x: calc(100% - 5px);
        display: flex;
        align-items: center;
        flex-direction: column;
        .testimonial-carousel--item-overlay {
          background: #0022B7;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          z-index: -1;
        }
        .testimonial-carousel--item-quote-container {
          .logo {
            width: 503px;
            height: 131px;
            margin-bottom: 24px;
            img {
              width: 100%;
            }
          }
          padding: 64px;
          @media (max-width: 576px) {
            padding: 32px;
          }
          .testimonial-carousel--item-quote {
            font-weight: 700;
            font-size: 26px;
            line-height: 34px;
            letter-spacing: 0.1px;
            color: #FFFFFF;
            @media (max-width: 576px) {
              font-size: 20px;
              line-height: 28px;
            }
          }
          .testimonial-carousel--item-author {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            margin: 16px 0 0;
            b {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .slick-slider {
    [aria-hidden="true"] {
      .testimonial-carousel--item {
        .testimonial-carousel--item-wrapper {
          .testimonial-carousel--item-overlay {
            background: #D0D5DD;
            transition: all 0.2s ease 0s;
          }
        } 
      }
    }
    .slick-dots {
      top: -64px;
      height: fit-content;
    }
    .slick-dots li button:before {
      font-size: 10px;
    }
    .slick-arrow {
      top: -50px;
      width: 30px;
      height: 30px;
      background: #0022B7;
      border-radius: 50%;
      margin: 0 500px;
      @media (max-width: 1200px) {
        margin: 0 400px;
      }
      @media (max-width: 992px) {
        margin: 0 300px;
      }
      @media (max-width: 768px) {
        margin: 0 200px;
      }
      @media (max-width: 576px) {
        margin: 0 100px;
      }
      cursor: pointer;
      z-index: 10;

      &:before {
        display: block;
        content: ' ';
        background-image: url('/icons/chevron-right-white-thin.svg');
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.slick-prev {
        &:before {
          transform: rotate(180deg);
        }
      }
      &.slick-next {
      }
      &.slick-disabled {
        background: #D0D5DD;
        
        &:before {
          opacity: 1;
        }
      }
    }
  }
`;

export { TestimonialCarouselWrapper }